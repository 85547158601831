import React from 'react';
import {connect} from 'react-redux';
import { TabList, Button } from 'tyb';
// const { loadEnterprisesSelections, } = iceStarkData.store.get('commonAction')?.enterprises;

import { BrandTable, ProductTable } from './components/index';


import './index.scss';


@connect (state => {
  return {
    ...state.wechat,
    openIds: state.users.openIds,
  };
}, {
  // loadQueryUserMetadata: loadQueryUserMetadata.REQUEST,
})
class ProductMange extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      tabListCurrentTab: 'product',
      tabPageList: [
        {
          text: '产品',
          value: 'product',
        },
        {
          text: '品牌',
          value: 'brand',
        },
      ],
    };
  }

  componentDidMount () {}

  // 标签切换
  tabListChange = (val) => {
    const {currentAppid, currentOpenId, lotteryRecordPage = {}} = this.props;
    //  设置列表显示
    this.setState({ tabListCurrentTab: val });
    // this.props.updateTabListCurrentTab({ tabListCurrentTab: val });
  };

  render () {
    const { tabPageList, tabListCurrentTab, } = this.state;
    const {} = this.props;
    return (
      <div className="productmanage-container">
     

        <TabList
          list={tabPageList}
          value={tabListCurrentTab}
          onChange={this.tabListChange}
        />

        {tabListCurrentTab == 'brand' && <BrandTable />}
        {tabListCurrentTab == 'product' && <ProductTable {...this.props} />}

      </div>
    );
  }
}

export default ProductMange;
